import { Table, TableHead, TableBody, TableCell, TableRow, Tooltip } from '@contentful/forma-36-react-components';
import * as React from 'react';
export default {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  React
};