import zIndexTokens from '@contentful/forma-36-tokens/src/tokens/z-index';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@contentful/forma-36-react-components';
import * as React from 'react';
export default {
  zIndexTokens,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  React
};