import ColorSwatchGroup from "../../../../src/components/ColorSwatchGroup";
import ColorA11yGroup from "../../../../src/components/ColorA11yGroup";
import ColorBox from "../../../../src/components/ColorBox";
import semanticColors from '@contentful/forma-36-tokens/src/tokens/colors/colors-semantic';
import grayColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-gray';
import blueColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-blue';
import greenColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-green';
import redColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-red';
import orangeColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-orange';
import purpleColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-purple';
import yellowColors from '@contentful/forma-36-tokens/src/tokens/colors/scale-yellow';
import whiteColors from '@contentful/forma-36-tokens/src/tokens/colors/colors-white';
import blackColors from '@contentful/forma-36-tokens/src/tokens/colors/colors-black';
import * as React from 'react';
export default {
  ColorSwatchGroup,
  ColorA11yGroup,
  ColorBox,
  semanticColors,
  grayColors,
  blueColors,
  greenColors,
  redColors,
  orangeColors,
  purpleColors,
  yellowColors,
  whiteColors,
  blackColors,
  React
};