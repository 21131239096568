import durationTokens from '@contentful/forma-36-tokens/src/tokens/transitions/transition-durations';
import easingsTokens from '@contentful/forma-36-tokens/src/tokens/transitions/transition-easings';
import Slider, { SliderKnob } from "../../../../src/content/foundation/transitions/slider.jsx";
import { Table, TableHead, TableBody, TableCell, TableRow, Card } from '@contentful/forma-36-react-components';
import * as React from 'react';
export default {
  durationTokens,
  easingsTokens,
  Slider,
  SliderKnob,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Card,
  React
};