import fontBaseTokens from '@contentful/forma-36-tokens/src/tokens/typography/font-base';
import fontSizeTokens from '@contentful/forma-36-tokens/src/tokens/typography/font-size';
import fontWeightTokens from '@contentful/forma-36-tokens/src/tokens/typography/font-weight';
import lineHeightTokens from '@contentful/forma-36-tokens/src/tokens/typography/line-height';
import fontStackTokens from '@contentful/forma-36-tokens/src/tokens/typography/font-stack';
import letterSpacingTokens from '@contentful/forma-36-tokens/src/tokens/typography/letter-spacing';
import { Card, Typography, Paragraph, Heading, Subheading, SectionHeading, DisplayText, Table, TableHead, TableBody, TableCell, TableRow } from '@contentful/forma-36-react-components';
import * as React from 'react';
export default {
  fontBaseTokens,
  fontSizeTokens,
  fontWeightTokens,
  lineHeightTokens,
  fontStackTokens,
  letterSpacingTokens,
  Card,
  Typography,
  Paragraph,
  Heading,
  Subheading,
  SectionHeading,
  DisplayText,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  React
};