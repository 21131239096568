import boxShadowTokens from '@contentful/forma-36-tokens/src/tokens/box-shadows/box-shadows';
import glowTokens from '@contentful/forma-36-tokens/src/tokens/box-shadows/glows';
import { Table, TableHead, TableBody, TableCell, TableRow, Card } from '@contentful/forma-36-react-components';
import * as React from 'react';
export default {
  boxShadowTokens,
  glowTokens,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Card,
  React
};