import workbenchSkeleton from "../../../../src/content/guidelines/layout/workbench/workbench-skeleton.jpg";
import titleBarSkeleton from "../../../../src/content/guidelines/layout/workbench/titlebar-skeleton.jpg";
import rightSidebarSkeleton from "../../../../src/content/guidelines/layout/workbench/rightsidebar-skeleton.jpg";
import leftSidebarSkeleton from "../../../../src/content/guidelines/layout/workbench/leftsidebar-skeleton.jpg";
import mainContentSkeleton from "../../../../src/content/guidelines/layout/workbench/maincontent-skeleton.jpg";
import skeleton from "../../../../src/content/guidelines/layout/workbench/maincontent-skeleton.jpg";
import * as React from 'react';
export default {
  workbenchSkeleton,
  titleBarSkeleton,
  rightSidebarSkeleton,
  leftSidebarSkeleton,
  mainContentSkeleton,
  skeleton,
  React
};